import axios from 'axios';

export const VOUCHER_BASE = '/cycled/v1';
export const VOUCHER_BASE_CMN = '/cmn/v1';

export const axiosVouchers = axios.create({
  // eslint-disable-next-line no-undef
  baseURL: process.env.REACT_APP_VS_BASE_URL
});

const axiosInstance = axios.create({
  // eslint-disable-next-line no-undef
  baseURL: process.env.REACT_APP_BASE_URL,
  withCredentials: 'include',
});

// axiosInstance.CancelToken = axios.CancelToken;
var refreshTokenLoading = false;
const restActions = {
  GET: (url, config = '') => {
    return axiosInstance.get(url, config);
  },
  POST: (url, data, config = '') => {
    return axiosInstance.post(url, data, config);
  },
  PUT: (url, data, config = '') => {
    return axiosInstance.put(url, data, config);
  },
  DELETE: (url) => {
    return axiosInstance.delete(url);
  },
  SETUP: (history) => {
    axiosInstance.interceptors.request.use(
      (config) => {
        // here do the interceptor work
        const token = localStorage.getItem('token');
        /**
         * Registring a user and login does not require a bearer token
         */
        if (!config.url.match('accounts/login') && !config.url.match('signup')) {
          config.headers['Authorization'] = 'Bearer ' + token;
        }
        return config;
      },
      // eslint-disable-next-line no-unused-vars
      (err) => {
        // console.err(err)
      }
    );

    axiosInstance.interceptors.response.use(
      (res) => {
        return res;
      },
      async (err) => {
        if (err.response) {
          /**
           * Provide specific error to show with status code err.response.status
           */
          if (err) {
            if (err.response.status == 401) {
              // console.log(err);
              // console.log(err.response);
              const config = err.response.config;
              if (!config.url.match('account') && !config.url.match('signup') && !config.url.match('refresh-token')) {
                if (!refreshTokenLoading) {
                  refreshTokenLoading = true;
                  try {
                    const res = await axiosInstance.post('/refresh-token');
                    window.location.reload();
                    refreshTokenLoading = false;
                    if (res.data) {
                      localStorage.setItem('token', res.data.token);
                      return await axiosInstance.request(config);
                    }
                  } catch (err) {
                    refreshTokenLoading = false;
                    localStorage.clear();
                    history.push('/login');
                  }
                } else {
                  return await axiosInstance.request(config);
                }
              } else {
                localStorage.clear();
                history.push('/login');
              }
            }
            return Promise.reject({ error: err.response, status: err.response.status });
          }
        } else {
          return Promise.reject({ error: err?.error?.data?.message || 'Network Error' });
        }
      }
    );
  },
};

export default restActions;
