import restActions from "src/actions/Rest";
import { toastManager } from "src/reusable/toaster-manager";
import store from "src/store";
import { constants } from "./constants";
import getUser, { getDeviceId } from "./user";

export const processFailedQr = async () => {
  if (!getUser()) {
    return;
  }

  let localScanData = JSON.parse(localStorage.getItem("failedQrScans") ?? "[]");
  if (localScanData.length > 0) {
    const { userId, url, session, params } = localScanData[0];

    restActions.POST("/events", {
      name: constants.EVENT_NAME.SCANNER_PARAMS,
      eventData: { scannerParams: params, url },
      userId,
      deviceId: getDeviceId()
    });

    try {
      if (session) {
        await restActions.POST("/sessions/create", { userId, session });

        // toastManager.info('Please go ahead with disposals, we will add incentives for your disposals to your account');
      } else {
        const res = await restActions.GET(`/transactions/create?bin=${params}`); //+'&data='+data)

        if (res?.data?.transaction?.id) {
          restActions
            .GET(
              `/transactions/registerUser?userId=${userId}&transactionId=${res.data.transaction.id}`
            )
            .then((incRes) => {
              console.log(incRes?.data);
            })
            .catch((err) => {
              console.error(err);
            });
        }
      }
    } catch (error) {
      console.log("offline.js", "processFailedQr failed:- ", error);
    }

    localScanData.splice(0, 1);
    // console.log("data cleared", localScanData);
    updateUserUsages(localScanData);

    processFailedQr();
  }
};

// Mainly called from Scanner component when the API request fails after QR scan
export const saveFailedQrToLocal = (url, session, params) => {
  let localScanData = JSON.parse(localStorage.getItem("failedQrScans") ?? "[]");
  const scanData = { userId: getUser()?.userId, url, params, session };
  localScanData = [...localScanData, scanData];
  updateUserUsages(localScanData);
};

// Update store to refect current pending usages for logged in user.
export const updateUserUsages = (localScanData) => {
  let pendingUsages = 0;
  localScanData?.map((qr) => {
    qr.userId === getUser()?.userId && pendingUsages++;
  });
  store.dispatch({ type: "set", pendingUsages });
  // console.log('saved failed scans', localScanData);
  localStorage.setItem("failedQrScans", JSON.stringify(localScanData));
};
