// import { sygnet } from './sygnet'

/* import {
  cibSkype,
  cibFacebook,
  cibTwitter,
  cibLinkedin,
  cibFlickr,
  cibTumblr,
  cibXing,
  cibGithub,
  cibStackoverflow,
  cibYoutube,
  cibDribbble,
  cibInstagram,
  cibPinterest,
  cibVk,
  cibYahoo,
  cibBehance,
  cibReddit,
  cibVimeo,
  cibCcMastercard,
  cibCcVisa,
  cibStripe,
  cibPaypal,
  cibGooglePay,
  cibCcAmex
} from '@coreui/icons' */
/* import {
  cifUs,
  cifBr,
  cifIn,
  cifFr,
  cifEs,
  cifPl
} from '@coreui/icons' */
import {
  // cilAlignCenter,
  // cilAlignLeft,
  // cilAlignRight,
  // cilApplicationsSettings,
  // cilArrowRight,
  // cilArrowTop,
  // cilAsterisk,
  // cilBan,
  // cilBasket,
  cilBell,
  // cilBold,
  // cilBookmark,
  // cilCalculator,
  // cilCalendar,
  cilCloudDownload,
  cilChartPie,
  // cilCheck,
  cilChevronBottom,
  // cilChevronLeft,
  // cilChevronRight,
  // cilChevronTop,
  // cilCircle,
  // cilCheckCircle,
  // cilCode,
  // cilCommentSquare,
  cilCommentBubble,
  // cilCreditCard,
  // cilCursor,
  // cilCursorMove,
  // cilDrop,
  // cilDollar,
  // cilEnvelopeClosed,
  // cilEnvelopeLetter,
  cilEnvelopeOpen,
  // cilEuro,
  cilGem,
  cilGlobeAlt,
  // cilGrid,
  // cilFile,
  // cilFullscreen,
  // cilFullscreenExit,
  cilGraph,
  cilHome,
  // cilInbox,
  // cilIndentDecrease,
  // cilIndentIncrease,
  // cilInputPower,
  // cilItalic,
  // cilJustifyCenter,
  // cilJustifyLeft,
  // cilLaptop,
  // cilLayers,
  // cilLightbulb,
  cilList,
  // cilListNumbered,
  // cilListRich,
  cilLocationPin,
  cilLockLocked,
  cilMagnifyingGlass,
  // cilMap,
  // cilMoon,
  // cilNotes,
  // cilOptions,
  // cilPaperclip,
  // cilPaperPlane,
  cilPencil,
  // cilPeople,
  // cilPhone,
  // cilPrint,
  // cilPuzzle,
  // cilSave,
  // cilScrubber,
  // cilSettings,
  // cilShare,
  // cilShareAll,
  // cilShareBoxed,
  // cilShieldAlt,
  // cilSpeech,
  // cilSpeedometer,
  // cilSpreadsheet,
  // cilStar,
  // cilSun,
  // cilTags,
  // cilTask,
  cilTrash,
  // cilUnderline,
  cilUser,
  // cilUserFemale,
  // cilUserFollow,
  // cilUserUnfollow,
  cilX,
  // cilXCircle,
  // cilWarning,
  cilGift,
  cilSearch,
  cilBackspace,
  cilCopy,
  cilHistory,
  cilQrCode,
  cilGamepad,
} from '@coreui/icons'

export const icons = Object.assign({}, /* {
  sygnet,
}, */ {
  // cilAlignCenter,
  // cilAlignLeft,
  // cilAlignRight,
  // cilApplicationsSettings,
  // cilArrowRight,
  // cilArrowTop,
  // cilAsterisk,
  // cilBan,
  // cilBasket,
  cilBell,
  // cilBold,
  // cilBookmark,
  // cilCalculator,
  // cilCalendar,
  cilCloudDownload,
  cilChartPie,
  // cilCheck,
  cilChevronBottom,
  // cilChevronLeft,
  // cilChevronRight,
  // cilChevronTop,
  // cilCircle,
  // cilCheckCircle,
  // cilCode,
  // cilCommentSquare,
  cilCommentBubble,
  // cilCreditCard,
  // cilCursor,
  // cilCursorMove,
  // cilDrop,
  // cilDollar,
  // cilEnvelopeClosed,
  // cilEnvelopeLetter,
  cilEnvelopeOpen,
  // cilEuro,
  cilGem,
  cilGlobeAlt,
  // cilGrid,
  // cilFile,
  // cilFullscreen,
  // cilFullscreenExit,
  cilGraph,
  cilHome,
  // cilInbox,
  // cilIndentDecrease,
  // cilIndentIncrease,
  // cilInputPower,
  // cilItalic,
  // cilJustifyCenter,
  // cilJustifyLeft,
  // cilLaptop,
  // cilLayers,
  // cilLightbulb,
  cilList,
  // cilListNumbered,
  // cilListRich,
  cilLocationPin,
  cilLockLocked,
  cilMagnifyingGlass,
  // cilMap,
  // cilMoon,
  // cilNotes,
  // cilOptions,
  // cilPaperclip,
  // cilPaperPlane,
  cilPencil,
  // cilPeople,
  // cilPhone,
  // cilPrint,
  // cilPuzzle,
  // cilSave,
  // cilScrubber,
  // cilSettings,
  // cilShare,
  // cilShareAll,
  // cilShareBoxed,
  // cilShieldAlt,
  // cilSpeech,
  // cilSpeedometer,
  // cilSpreadsheet,
  // cilStar,
  // cilSun,
  // cilTags,
  // cilTask,
  cilTrash,
  // cilUnderline,
  cilUser,
  // cilUserFemale,
  // cilUserFollow,
  // cilUserUnfollow,
  cilX,
  // cilXCircle,
  // cilWarning,
  cilGift,
  cilSearch,
  cilBackspace,
  cilCopy,
  cilHistory,
  cilQrCode,
  cilGamepad
}/* , {
  cifUs,
  cifBr,
  cifIn,
  cifFr,
  cifEs,
  cifPl
}, {
  cibSkype,
  cibFacebook,
  cibTwitter,
  cibLinkedin,
  cibFlickr,
  cibTumblr,
  cibXing,
  cibGithub,
  cibStackoverflow,
  cibYoutube,
  cibDribbble,
  cibInstagram,
  cibPinterest,
  cibVk,
  cibYahoo,
  cibBehance,
  cibReddit,
  cibVimeo,
  cibCcMastercard,
  cibCcVisa,
  cibStripe,
  cibPaypal,
  cibGooglePay,
  cibCcAmex
} */)
