import Geocode from 'react-geocode';

// eslint-disable-next-line no-undef
Geocode.setApiKey(process.env.REACT_APP_MAPS_API_KEY);
Geocode.setLocationType('ROOFTOP');
Geocode.enableDebug();

export const getGeocode = async function (lat, lan) {
  const res = await Geocode.fromLatLng(lat, lan);
  return res.results[0];
};

// To get current location (not use previous stored one) to send, mainly when user redirected through deep linking
export async function getCurrentLocation() {
  return new Promise((resolve) => {
    let deviceInfo = JSON.parse(localStorage.getItem('userDeviceData') ?? '{}');

    navigator.geolocation.getCurrentPosition(
      (position) => {
        resolve({ lat: position.coords.latitude, lng: position.coords.longitude });
      },
      (error) => {
        console.error(error);
        resolve({ lat: deviceInfo?.lat, lng: deviceInfo?.lan });
      },
      { maximumAge: 200000, enableHighAccuracy: true, timeout: 1000 * 20 }
    );
  })
}
