import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { App } from "@capacitor/app";
import { LocalNotifications } from "@capacitor/local-notifications";
import { PushNotifications } from "@capacitor/push-notifications";

// Here we handle deep linking
const AppUrlListener = () => {
  let history = useHistory();

  useEffect(() => {
    const redirectToScreen = (redirectScreen) => {
      history.push(
        redirectScreen && redirectScreen !== "/"
          ? redirectScreen
          : "/notification"
      );
    };

    App.addListener("appUrlOpen", (event) => {
      // Ex.:- '/login?bin=87&...'
      const slug = event.url.split(".com").pop();
      if (slug) {
        history.push(slug);
      }
    });

    LocalNotifications.addListener(
      "localNotificationActionPerformed",
      (action) => {
        redirectToScreen(`/${action.notification.actionTypeId ?? ""}`);
      }
    );
    PushNotifications.addListener(
      "pushNotificationActionPerformed",
      (action) => {
        redirectToScreen(
          `/${action.notification.data.redirect_to_screen ?? ""}`
        );
      }
    ).catch((err) => console.log("pushNotificationActionPerformed", err));
  }, [history]);

  return null;
};

export default AppUrlListener;
