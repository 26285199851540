import { createStore } from 'redux';
import { StatusBar, Style } from '@capacitor/status-bar';
import { constants } from './helper/constants';
import { Capacitor } from '@capacitor/core';

const initialState = {
  sidebarShow: 'responsive',
  country: constants.COUNTRIES[0],
  isLoggedIn: false,
  toasters: [],
  configurations: [],
  // User
  userPoints: 0,
  pendingUsages: 0,
  unreadNotifications: 0,
  enabledFeatures: [],
  // VoucherSkout
  filterCategory: '',
  openedOfferName: '',
  openSearchModal: false,
};

// function findCountry(country) {
//   return country.name == 'uae';
// }

const changeState = (state = initialState, { type, ...rest }) => {
  //console.log('Stage changed');
  //console.log({...state});
  switch (type) {
    case 'set':
      // change status bar text color based on the content
      if (Capacitor.getPlatform() === 'ios' && Object.prototype.hasOwnProperty.call(rest, 'sidebarShow') && state.sidebarShow !== rest.sidebarShow) {
        StatusBar.setStyle({ style: rest.sidebarShow === true ? Style.Dark : Style.Light }).catch(() => {});
      }
      return { ...state, ...rest };

    default:
      return state;
  }
};

const store = createStore(changeState);
export default store;
