import { Device } from "@awesome-cordova-plugins/device";
import { Capacitor } from "@capacitor/core";
import restActions from "src/actions/Rest";
import getUser from "./user";

export const saveDeviceData = async (deviceInfo, saveOnLocal = true) => {
  let localUserData = JSON.parse(
    localStorage.getItem("userDeviceData") ?? "{}"
  );
  const userData = { ...localUserData, ...deviceInfo };

  let userId = deviceInfo.userId;
  if (!userId) {
    userId = getUser()?.userId;
  }

  saveOnLocal &&
    localStorage.setItem("userDeviceData", JSON.stringify(userData));

  if (userId) {
    await restActions.POST("/devices", {
      ...userData,
      userId,
      deviceId: Device.uuid,
      deviceType: Capacitor.getPlatform().toUpperCase(),
    });
  }
};
